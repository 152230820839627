import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  FieldVerification,
  banks,
  getAllCases,
  getAllCasesByMultipleFilter,
  getAllCasesfreesearch,
  getdownloaddocs,
  updateCaseBulkStatus,
  updateCaseDetails,
  updatecasesvendor,
} from "./caseservice";
import { formatDate, getStatusStyle } from "../../utils/dateformat";
import { getAllClients } from "../client/clientservice";
import Pagenation from "../../sharecomponent/pagenation/page";
import writeXlsxFile from "write-excel-file";
import { showToast } from "../../utils/notification";
import Bulkuploadmodal from "./bulkuploadmodal/bulkuploadmodal";
import SSpinner from "../../sharecomponent/spinner/spinner";
import { useAuth } from "../../authprovider";
import { TBill } from "./caseservice";
import { AccountSearch } from "./caseservice";
import { CIBIL } from "./caseservice";
import { ITO } from "./caseservice";
import { RxCross2 } from "react-icons/rx";
import Picker from "../../sharecomponent/datepicker/picker";
import Sharemodal from "./sharemodal";
import { getAllActiveVendors } from "../vendor/vendorservice";
import { FaCopy } from "react-icons/fa6";
import { extractFileExtension } from "../../utils/common";

const statusList = ["New", "In Process", "Closed", "CNV", "Error", "Invalid"];
export default function Caseview() {
  const { userDetail } = useAuth();
  const navigator = useNavigate();
  const [pageno, setPageNo] = useState(1);
  const [pagesize, setpageSize] = useState(20);
  const [inputvalue, setinputvalue] = useState("");
  const [total, setTotal] = useState(0);
  const [caseList, setCaseList] = useState([]);
  const [modalshow, setModalshow] = useState(false);
  const [loder, setloder] = useState(false);
  const [sharemodaltogle, setSharemodaltogle] = useState(false);
  const productTypeList = [
    "ITO",
    "Banking",
    "UID",
    "UAN",
    "Banking Retravels",
    "T Bill",
    "Account Search",
    "Cibil",
    "Field Verification",
  ];
  const [productType, setProductType] = useState("");
  const [productnamelist, setProductnamelist] = useState([]);
  const [copy, setcopy] = useState(false);
  const [caseFilters, setCaseFilters] = useState({
    pageno: pageno,
    pagesize: pagesize,
    clientId: "",
    productType: "",
    productName: "",
    start: "",
    end: "",
    status: "",
    vendorId: "",
    dupe: "",
  });
  const [clientList, setClientList] = useState([]);
  const [caseoder, setCaseoder] = useState(false);
  const [selectedstatus, setSelectedstatus] = useState("");
  const [updatemultiplestatus, setUpdatemultiplestatus] = useState([]);
  const [checkall, setCheckall] = useState(false);
  const [link, setLink] = useState("");
  const [sharemassage, setSharemessage] = useState("");
  const [vendorList, setVendorList] = useState([]);
  const [remark, setRemark] = useState([]);
  const [vendoestate, setVendorState] = useState([]);
  const [remarkval, setRemarkval] = useState("");
  const [refresh, setRefresh] = useState(false);
  const selectedItem = useMemo(() => {
    return updatemultiplestatus
      .map((item) => (item.check ? item.id : null))
      .filter((id) => id !== null);
  }, [updatemultiplestatus]);

  const downloadFile = async (data) => {
    const HEADER_ROW = [
      {
        value: "Case Id",
        fontWeight: "bold",
      },
      {
        value: "Client Id",
        fontWeight: "bold",
      },
      {
        value: "Source",
        fontWeight: "bold",
      },
      {
        value: "Product Type",
        fontWeight: "bold",
      },
      {
        value: "Product Name",
        fontWeight: "bold",
      },
      {
        value: "Account No",
        fontWeight: "bold",
      },
      {
        value: "Reruirement",
        fontWeight: "bold",
      },
      {
        value: "Assign To Vendor",
        fontWeight: "bold",
      },
      {
        value: "Manage By",
        fontWeight: "bold",
      },
      {
        value: "Remark",
        fontWeight: "bold",
      },

      {
        value: "Attachments",
        fontWeight: "bold",
      },
      {
        value: "Status",
        fontWeight: "bold",
      },
      {
        value: "Created On",
        fontWeight: "bold",
      },
      {
        value: "Created  By",
        fontWeight: "bold",
      },
      {
        value: "Updated By",
        fontWeight: "bold",
      },
      {
        value: "Updated On",
        fontWeight: "bold",
      },
    ];

    const columns = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 40 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];

    const convertintostring = (val) => {
      let urls = "";
      val.forEach((element, index) => {
        urls += element;
        if (index < val.length - 1) {
          urls += ",\n";
        }
      });
      return urls;
    };
    let datacontent = [HEADER_ROW];
    const row = data.forEach((item) => {
      datacontent.push([
        {
          type: String,
          value: `${item.casecollectionid}`,
        },
        {
          type: String,
          value: item.clientId ? item.clientId : "",
        },
        {
          type: String,
          value: item.source,
        },
        {
          type: String,
          value: item.productType,
        },
        {
          type: String,
          value: item.productName,
        },
        {
          type: String,
          value: item.accountNumber,
        },
        {
          type: String,
          value: item.requirement,
        },
        {
          type: String,
          value: item?.vendorId ? item?.vendorId : "",
        },
        {
          type: String,
          value: item.assignTo ? item.userDetails.name : "",
        },
        {
          type: String,
          value: item.remark,
        },

        {
          type: String,
          value: convertintostring(item.attachment),
        },
        {
          type: String,
          value: item.status,
        },
        {
          type: String,
          value: formatDate(item.createdAt),
        },
        {
          type: String,
          value: item.createdBy ? item.createdBy.name : "",
        },
        {
          type: String,
          value: item.closedBy ? item.closedBy.name : "",
        },
        {
          type: String,
          value: formatDate(item.closedOn),
        },
      ]);
    });
    // datacontent = [...datacontent, row];s

    const datat = await writeXlsxFile(datacontent, {
      columns: columns,
      fileName: "Documents.xlsx",
    });
    setloder(false);
    setRefresh(!refresh);
  };

  const fetchdownloadData = async () => {
    const data = updatemultiplestatus
      .map((item) => (item.check ? item.id : null))
      .filter((id) => id !== null);
    if (data.length == 0) {
      showToast("Select Cases", "error");
    }
    const val = {
      data: data,
    };
    setloder(true);
    const response = await getdownloaddocs(val);
    setloder(false);
    if (response && data.length > 0) {
      // console.log(response);
      setloder(true);
      downloadFile(response.docs);
    }
  };

  const fetchShareData = async () => {
    const data = updatemultiplestatus
      .map((item) => (item.check ? item.id : null))
      .filter((id) => id !== null);
    if (data.length == 0) {
      showToast("Select Cases", "error");
    }
    const val = {
      data: data,
      share: true,
    };
    setloder(true);
    const response = await getdownloaddocs(val);
    setloder(false);
    if (response && data.length > 0) {
      const dataval = response;
      setLink(dataval);
      let message = "";

      message += ` Please download your documents below:\n\n`;

      message += `${dataval}\n`;

      message += "\n";

      return message;
    }
  };

  const updateremark = async (caseId, payload) => {
    // setloder(true);
    const response = await updateCaseDetails(caseId, payload);
    setRefresh(!refresh);
    // setloder(false);
    // if (response && response.status == 200) {
    //   showToast("Case updated successfully", "success");
    //
    // } else if (response && response.status == 400) {
    //   showToast("Account no already exist", "warning");
    // }
  };

  const copySelectedData = () => {
    const data = updatemultiplestatus
      .map((item) => (item.check ? item : null))
      .filter((item) => item !== null);
    const modifiedData = data.map((item) => item.id);
    const filteredArray = caseList
      .filter((obj) => modifiedData.includes(obj._id))

      .map(
        (item) =>
          `Case ID: #${item.casecollectionid}\nProduct Name: ${
            item.productName ? item.productName : item.productType
          }\nAccount No: ${item.accountNumber}\nRequirement: ${
            item.requirement
          }`
      )
      .join("\n\n");

    return filteredArray;
  };

  const handleCopy = () => {
    const dataToCopy = copySelectedData();

    return (
      <CopyToClipboard text={dataToCopy}>
        <button
          onClick={() => {
            setcopy(true);
          }}
          className="ccopy_btn"
          title="Copy Selected Case Details"
        >
          <FaCopy size={"1em"} /> {copy ? "Copied" : "Copy"}
        </button>
      </CopyToClipboard>
    );
  };

  const updatemultiplevendor = async (vendorId) => {
    const data = await updatemultiplestatus
      .map((item) => (item.check ? item.id : null))
      .filter((id) => id !== null);

    const postdata = {
      vendorId: vendorId,
      caseIds: data,
    };

    const response = await updatecasesvendor(postdata);
    if (response) {
      setRefresh(!refresh);
    }
  };

  const fetchVendorsList = async () => {
    const result = await getAllActiveVendors();
    if (result) {
      setVendorList(result);
    }
  };
  const fetchallcases = async () => {
    setloder(true);
    const response = await getAllCases(pageno, pagesize, caseoder);
    setloder(false);
    if (response) {
      const detailList = response.docs;
      setCaseList(detailList);
      setTotal(response.totalDocs);
      const modefystatus = detailList.map((item) => ({
        id: item?._id,
        status: selectedstatus,
        check: false,
      }));
      const remarkstate = detailList.map((item) => true);
      setRemark(remarkstate);
      setSelectedstatus("");
      setUpdatemultiplestatus(modefystatus);
      setCheckall(false);
    }
  };

  const findclient = (id, clientf = true) => {
    if (clientf) {
      const client = clientList.find((item) => item._id == id);
      if (client) {
        return client.clientId;
      }
    } else if (!clientf) {
      const vendor = vendorList.find((item) => item._id == id);
      if (vendor) {
        return vendor.vendorId;
      }
    }
    return "";
  };

  const fetchClientList = async () => {
    const response = await getAllClients(1, 1000000);
    if (response) {
      setClientList(response.docs);
    }
  };

  const fetchallcasesByFiltrs = async () => {
    setloder(true);
    let data = caseFilters;
    data = { ...data, caseorder: caseoder, pageno: pageno, pagesize: pagesize };
    const response = await getAllCasesByMultipleFilter(data);
    setloder(false);
    if (response) {
      if (response.docs.length > 0) {
        showToast("Data Found Successfully", "success");
      }
      setCaseList(response.docs);
      setTotal(response.totalDocs);
      const datalist = response.docs;
      const modefystatus = datalist.map((item) => ({
        id: item?._id,
        status: selectedstatus,
        check: false,
      }));
      const remarkstate = datalist.map((item) => true);
      setRemark(remarkstate);
      setSelectedstatus("");
      setCheckall(false);
      setUpdatemultiplestatus(modefystatus);
    } else {
      showToast("No Data Found", "warring");
    }
  };

  const updatebulkstatus = async (modefyarr) => {
    const response = await updateCaseBulkStatus({ status: modefyarr });
    if (response && response.status == 200) {
      showToast("Updated Successfully", "success");
      setRefresh(!refresh);
    } else {
      showToast("Sorry Please Select Correct Data", "warning");
    }
  };

  const fetchfreesearch = async (val) => {
    setloder(true);
    const response = await getAllCasesfreesearch({
      pageno: pageno,
      pagesize: pagesize,
      val: val,
    });
    setloder(false);
    if (response) {
      setCaseList(response.docs);
      const datalist = response.docs;
      const modefystatus = datalist.map((item) => ({
        id: item?._id,
        status: selectedstatus,
        check: false,
      }));
      const remarkstate = datalist.map((item) => true);

      setRemark(remarkstate);
      setSelectedstatus("");
      setCheckall(false);
      setUpdatemultiplestatus(modefystatus);
    }
  };

  useEffect(() => {
    if (selectedstatus) {
      const modefyarr = updatemultiplestatus.map((item) => ({
        ...item,
        status: selectedstatus,
      }));

      const filterarr = modefyarr.filter(
        (item) => item.check == true && item.status != null
      );

      if (filterarr.length > 0) {
        updatebulkstatus(filterarr);
      } else {
        showToast("Select Correct Data", "warning");
      }
    }
  }, [selectedstatus]);
  useEffect(() => {
    if (caseList.length > 0) {
      let data = caseList.map((item) => false);
      setVendorState(data);
    }
  }, [caseList]);

  useEffect(() => {
    if (inputvalue) {
      fetchfreesearch(inputvalue);
    } else if (
      caseFilters.clientId ||
      caseFilters.productType ||
      caseFilters.productName ||
      caseFilters.status ||
      caseFilters.vendorId ||
      caseFilters.dupe ||
      (caseFilters.start && caseFilters.end)
    ) {
      fetchallcasesByFiltrs();
    } else {
      fetchallcases();
    }
    setcopy(false);
  }, [pageno, inputvalue, caseFilters, pagesize, caseoder, refresh]);

  useEffect(() => {
    let val = productType;
    if (val == "Banking" || val == "Banking Retravels") {
      setProductnamelist(banks);
    } else if (val == "T Bill") {
      setProductnamelist(TBill);
    } else if (val == "Account Search") {
      setProductnamelist(AccountSearch);
    } else if (val == "Cibil") {
      setProductnamelist(CIBIL);
    } else if (val == "Field Verification") {
      setProductnamelist(FieldVerification);
    } else if (val == "ITO") {
      setProductnamelist(ITO);
    }
  }, [productType]);

  useEffect(() => {
    fetchClientList();
    fetchVendorsList();
  }, []);
  const boxRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        let temparr = vendoestate.map((item) => false);
        setVendorState(temparr);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Sharemodal
        link={link}
        message={sharemassage}
        show={sharemodaltogle}
        onChangevalue={(val) => {
          setSharemodaltogle(val);
        }}
      />
      <Bulkuploadmodal
        show={modalshow}
        caseId={updatemultiplestatus}
        onhide={(val) => {
          setModalshow(false);
          setRefresh(!refresh);
          // const modefystatus = updateCaseBulkStatus.map((item) => ({
          //   id: item?.id,
          //   status: selectedstatus,
          //   check: false,
          // }));
          // setUpdatemultiplestatus(modefystatus);
        }}
      />

      <div className="content-wrapper">
        <div className="page-header ">
          <h1 className="page-title">All Cases</h1>
        </div>

        <div className="d-flex mb-4 align-content-center">
          <div>
            <input
              value={inputvalue}
              onChange={(e) => {
                setinputvalue(e.target.value);
              }}
              type="text"
              className="form-control search_case_input"
              placeholder="Search Case"
            />
          </div>

          <div className="ms-auto d-flex">
            {userDetail.roleId.roleName !== "Client" && (
              <button
                onClick={() => {
                  setModalshow(true);
                }}
                type="button"
                className="btn btn-light btn-icon-text ms-2 tr_btns"
              >
                <i className=" icon-paper-clip btn-icon-prepend"></i> Add
                Attachment
              </button>
            )}

            <button
              disabled={loder}
              onClick={() => {
                fetchdownloadData();
              }}
              type="button"
              className="btn btn-light btn-icon-text ms-2 tr_btns"
            >
              <i className="icon-cloud-download btn-icon-prepend"></i> Download
            </button>

            <button
              type="button"
              className="btn btn-light btn-icon-text ms-2 tr_btns"
              // data-bs-toggle="modal"
              // data-bs-target="#downloadBackdrop"
              onClick={() => {
                const callapi = async () => {
                  const message = await fetchShareData();
                  setSharemessage(message);
                  if (message) setSharemodaltogle(true);
                };
                callapi();
              }}
            >
              <i className="icon-share btn-icon-prepend"></i> Share
            </button>

            {userDetail.roleId.roleName !== "Client" && (
              <div className="ms-2">
                <select
                  value={selectedstatus}
                  onChange={(e) => {
                    setSelectedstatus(e.target.value);
                  }}
                  className="form-select tr_btns_select"
                >
                  <option value={""}> Select Status</option>
                  <option value={"In Process"}> In Process </option>
                  <option value={"Closed"}> Closed </option>
                  <option value={"CNV"}> CNV </option>
                  <option value={"Error"}> Error </option>
                  <option value={"Invalid"}> Invalid </option>
                </select>
              </div>
            )}

            <button
              onClick={() => {
                navigator("/add-new-case");
              }}
              type="button"
              className="btn btn-light btn-icon-text ms-2 tr_btns"
            >
              <i className="icon-plus btn-icon-prepend"></i> Add New
            </button>

            <button
              type="button"
              className="btn btn-light btn-icon-text ms-2 tr_btns"
              data-bs-toggle="collapse"
              data-bs-target="#advanced_filter_collapse"
              aria-expanded="false"
              aria-controls="advanced_filter_collapse"
            >
              <i className=" icon-menu btn-icon-prepend"></i> Filter
            </button>
          </div>
        </div>

        <div className="collapse" id="advanced_filter_collapse">
          <div className="af_body">
            <div className="form-group d-flex mb-4">
              {userDetail.roleId.roleName !== "Client" && (
                <div className="flex-fill">
                  <label>By Client ID</label>
                  <select
                    value={caseFilters.clientId}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val)
                        setCaseFilters({ ...caseFilters, clientId: val });
                    }}
                    className="form-select"
                  >
                    <option>Select</option>
                    {clientList.map((item, index) => (
                      <option key={index} value={item?.clientId}>
                        {item.clientId}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {userDetail.roleId.roleName !== "Client" && (
                <div className="flex-fill ms-3">
                  <label>By Vendor</label>
                  <select
                    value={caseFilters.vendorId}
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val)
                        setCaseFilters({ ...caseFilters, vendorId: val });
                    }}
                    className="form-select "
                  >
                    <option value={""}> Select </option>
                    {vendorList.map((item, i) => (
                      <option key={i} value={item.vendorId}>
                        {item.vendorId}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="flex-fill ms-3">
                <label>By Product Type</label>
                <select
                  value={caseFilters.productType}
                  onChange={(e) => {
                    let val = e.target.value;
                    setProductType(val);
                    if (val)
                      setCaseFilters({
                        ...caseFilters,
                        productType: val,
                        productName: "",
                      });
                  }}
                  className="form-select"
                >
                  <option>Select</option>
                  {productTypeList.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>

              {caseFilters.productType !== "UID" &&
                caseFilters.productType !== "UAN" && (
                  <div className="flex-fill ms-3">
                    <label>By Product Name</label>
                    <select
                      value={caseFilters.productName}
                      onChange={(e) => {
                        let val = e.target.value;

                        if (val)
                          setCaseFilters({ ...caseFilters, productName: val });
                      }}
                      className="form-select"
                    >
                      <option>Select</option>
                      {productnamelist.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

              <div className="flex-fill ms-3">
                <label>By Status</label>
                <select
                  value={caseFilters.status}
                  onChange={(e) => {
                    let val = e.target.value;

                    if (val) {
                      setCaseFilters({ ...caseFilters, status: val });
                    }
                  }}
                  className="form-select "
                >
                  <option> Select Status</option>
                  {statusList.map((item, i) => (
                    <option value={item}> {item} </option>
                  ))}
                </select>
              </div>

              <div className="flex-fill ms-3">
                <label>By Dupe</label>
                <select
                  value={caseFilters.dupe}
                  onChange={(e) => {
                    let val = e.target.value;

                    if (val) {
                      setCaseFilters({ ...caseFilters, dupe: val });
                    }
                  }}
                  className="form-select "
                >
                  <option value={""}> Select</option>

                  <option key={"a"} value={"_D"}>
                    Dupe
                  </option>
                </select>
              </div>

              <div className="flex-fill ms-3">
                <label>By Date</label>
                <Picker
                  value={caseFilters.start}
                  ondatechange={(value) => {
                    if (value.from && value.to) {
                      setCaseFilters({
                        ...caseFilters,
                        start: value.from,
                        end: value.to,
                      });
                    }
                  }}
                />
              </div>
            </div>

            <ul className="d-flex filter_tags_list">
              <li>
                {caseFilters.clientId && (
                  <div>
                    <span>{caseFilters.clientId}</span>
                    <span
                      onClick={() => {
                        setCaseFilters({ ...caseFilters, clientId: "" });
                      }}
                      className="remove_tag"
                    >
                      <RxCross2 />
                    </span>
                  </div>
                )}
              </li>
              <li>
                {caseFilters.vendorId && (
                  <div>
                    <span>{caseFilters.vendorId}</span>
                    <span
                      onClick={() => {
                        setCaseFilters({ ...caseFilters, vendorId: "" });
                      }}
                      className="remove_tag"
                    >
                      <RxCross2 />
                    </span>
                  </div>
                )}
              </li>
              <li>
                {caseFilters.productType && (
                  <div>
                    <span>{caseFilters.productType}</span>
                    <span
                      onClick={() => {
                        setCaseFilters({ ...caseFilters, productType: "" });
                      }}
                      className="remove_tag"
                    >
                      <RxCross2 />
                    </span>
                  </div>
                )}
              </li>
              <li>
                {caseFilters.productName && (
                  <div>
                    <span>{caseFilters.productName}</span>
                    <span
                      onClick={() => {
                        setCaseFilters({ ...caseFilters, productName: "" });
                      }}
                      className="remove_tag"
                    >
                      <RxCross2 />
                    </span>
                  </div>
                )}
              </li>
              <li>
                {caseFilters.status && (
                  <div>
                    <span>
                      {caseFilters.status != "_D" ? caseFilters.status : "Dupe"}
                    </span>
                    <span
                      onClick={() => {
                        setCaseFilters({ ...caseFilters, status: "" });
                      }}
                      className="remove_tag"
                    >
                      <RxCross2 />
                    </span>
                  </div>
                )}
              </li>
              <li>
                {caseFilters.dupe && (
                  <div>
                    <span>{"Dupe"}</span>
                    <span
                      onClick={() => {
                        setCaseFilters({ ...caseFilters, dupe: "" });
                      }}
                      className="remove_tag"
                    >
                      <RxCross2 />
                    </span>
                  </div>
                )}
              </li>

              <li>
                {caseFilters.start && (
                  <div>
                    <span>
                      {`${formatDate(caseFilters.start)} -
                        ${formatDate(caseFilters.end)}`}
                    </span>
                    <span
                      onClick={() => {
                        setCaseFilters({ ...caseFilters, start: "", end: "" });
                      }}
                      className="remove_tag"
                    >
                      <RxCross2 />
                    </span>
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>

        {selectedItem.length > 0 && (
          <div className="d-flex align-content-center align-items-center mb-2 mt-10px">
            <div className="nofslect">
              <b>{selectedItem.length}</b> case selected
            </div>
            <div className="ms-3"> {handleCopy()}</div>
            {userDetail?.roleId?.roleName !== "Client" && (
              <div className="ms-3">
                <select
                  className="form-select fs_vendor"
                  title="Assign To Vendor"
                  name="vendorId"
                  onChange={(e) => {
                    let val = e.target.value;
                    if (val) {
                      updatemultiplevendor(val);
                    }
                  }}
                >
                  <option value={""}> Assign To Vendor </option>
                  {vendorList.map((item, i) => (
                    <option key={i} value={item.vendorId}>
                      {item.vendorId}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        )}

        <div className="row">
          <div className="col-md-12 grid-margin ">
            {loder && <SSpinner />}
            {caseList.length > 0 ? (
              <div className="table_parent">
                <table className="sm_font_size table table-striped">
                  <thead>
                    <tr>
                      <th>
                        <input
                          checked={checkall}
                          onChange={() => {
                            const check = !checkall;
                            const modefyarr = updatemultiplestatus.map(
                              (item) => ({
                                id: item.id,
                                status: selectedstatus,
                                check: check,
                              })
                            );
                            setCheckall(check);
                            setUpdatemultiplestatus(modefyarr);
                            setcopy(false);
                          }}
                          type="checkbox"
                          className="form-check-input"
                        />
                      </th>
                      <th
                        onClick={() => {
                          setCaseoder(!caseoder);
                        }}
                      >
                        Case ID
                      </th>
                      <th>Client ID</th>
                      <th>Source</th>
                      <th>Product Type</th>
                      <th>Product Name</th>
                      <th>Account No</th>
                      <th>Requirement</th>
                      {userDetail?.roleId?.roleName !== "Client" && (
                        <th>Assign To Vendor</th>
                      )}
                      <th>Manage By</th>
                      <th>Remark</th>
                      <th>Attachment</th>
                      <th>Status</th>

                      <th>Created On</th>
                      <th>Created By</th>

                      <th>Updated On </th>
                      <th>Updated By </th>
                    </tr>
                  </thead>
                  <tbody>
                    {caseList.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <input
                            checked={updatemultiplestatus[i]?.check}
                            onChange={() => {
                              const newarr = [...updatemultiplestatus];
                              let modefyval = newarr[i];

                              modefyval = {
                                ...modefyval,
                                check: !modefyval.check,
                              };

                              newarr[i] = modefyval;
                              setUpdatemultiplestatus(newarr);
                              setcopy(false);
                            }}
                            type="checkbox"
                            className="form-check-input"
                          />
                        </td>
                        <td>
                          {/* <Link to="/case-detail">{item._id}</Link> */}
                          <Link
                            to={`/case-detail?caseId=${item?._id}&id=${item.casecollectionid}`}
                          >
                            #{item.casecollectionid}
                          </Link>
                        </td>
                        <td>{item?.clientId}</td>
                        <td>{item.source}</td>
                        <td>{item.productType}</td>
                        <td>
                          {item.productName
                            ? item.productName
                            : item.productType}
                        </td>

                        <td>{item.accountNumber}</td>
                        <td>
                          <span title={item.requirement}>
                            {item.requirement.substring(0, 30)}
                            {item.requirement.length > 30 && "..."}
                          </span>
                        </td>
                        {userDetail.roleId.roleName !== "Client" && (
                          <>
                            {userDetail.roleId.roleName == "Client" ? (
                              <td>{item?.vendorId?.vendorId}</td>
                            ) : !vendoestate[i] ? (
                              <td>
                                <div
                                  className="edit_overview"
                                  title="Update Vendor"
                                  onClick={() => {
                                    let temparr = vendoestate.map(
                                      (item) => false
                                    );
                                    temparr[i] = true;
                                    setVendorState(temparr);
                                  }}
                                >
                                  <span> {item?.vendorId}</span>
                                </div>
                              </td>
                            ) : (
                              <td ref={boxRef}>
                                <div className="w-full">
                                  <select
                                    value={item.vendorId ? item.vendorId : ""}
                                    name="vendorId"
                                    onChange={(e) => {
                                      let val = e.target.value;
                                      if (val) {
                                        let data = {
                                          ...item,
                                          vendorId: val,
                                          status: "In Process",
                                        };
                                        updateremark(item._id, data);
                                      }
                                    }}
                                    className=""
                                  >
                                    <option value={""}> Select </option>
                                    {vendorList.map((item, i) => (
                                      <option key={i} value={item.vendorId}>
                                        {item.vendorId}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </td>
                            )}
                          </>
                        )}
                        <td>
                          {userDetail._id == item?.userDetails?._id &&
                          userDetail._id == item.createdBy?._id
                            ? "Self"
                            : item?.userDetails?.name}
                        </td>

                        <td
                          onClick={() => {
                            let temparr = remark.map((item) => true);
                            temparr[i] = false;
                            setRemark(temparr);
                            setRemarkval(item.remark);
                          }}
                        >
                          {remark[i] ? (
                            <span
                              className="add_remark_col"
                              title={item.remark}
                            >
                              {item.remark.substring(0, 30)}
                              {item.remark.length > 30 && "..."}
                            </span>
                          ) : (
                            <input
                              className="add_remark_input"
                              value={remarkval}
                              placeholder="Add Remark"
                              type="text"
                              onChange={(e) => {
                                let val = e.target.value;
                                setRemarkval(val);
                              }}
                              onBlur={() => {
                                if (remarkval) {
                                  updateremark(item._id, {
                                    ...item,
                                    remark: remarkval,
                                  });
                                }

                                let temparr = remark.map((item) => true);

                                setRemark(temparr);
                                setRemarkval("");
                              }}
                            />
                          )}
                        </td>
                        <td>
                          {item.attachment.length > 0 ? (
                            <>
                              {item.attachment.map((link, i) => (
                                <Link
                                  title="Click To Download"
                                  to={link}
                                  target="_blank"
                                  key={i}
                                  className="attached_filed_teble"
                                >
                                  <div>{`${
                                    item.accountNumber
                                  } .${extractFileExtension(link)}`}</div>
                                </Link>
                              ))}
                            </>
                          ) : (
                            "Not Avilable"
                          )}
                        </td>
                        <td>
                          <label
                            style={getStatusStyle(item.status)}
                            className="status_inprocess"
                          >
                            {item.status}
                          </label>
                        </td>
                        <td>{formatDate(item.createdAt)}</td>
                        <td>{item?.createdBy?.name}</td>
                        <td> {formatDate(item.closedOn)}</td>
                        <td>
                          {item?.closedBy?.name ? item?.closedBy?.name : "-"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div class="d-flex align-items-center justify-content-center w-100 my-4">
                  <Pagenation
                    page={pageno}
                    limit={pagesize}
                    total={total}
                    onChange={(val) => {
                      setPageNo(val);
                    }}
                    onChangeLimit={(val) => {
                      setpageSize(val);
                    }}
                  />
                </div>
              </div>
            ) : (
              !loder && <p>No Data Found</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
